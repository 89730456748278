

















import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import ModalSuccessError from './ModalSuccessError.vue';
import ModalWarning from './ModalWarning.vue';

const MODAL_WARNING = 'ModalWarning';

@Component({
  components: {
    Modal,
    ModalSuccessError,
    ModalWarning,
  },
})
export default class ModalChangedClass extends Vue {
  @Prop() componentNameModal!: string;
  @Prop() isSuccessfulChanged!: boolean;

  get showCloseButton() {
    return this.componentNameModal === MODAL_WARNING;
  }

  get isLoading() {
    return this.$store.getters.isLoadingChangedClass;
  }

  get clicks() {
    const CLICKED_CANCEL = 'clickedCancel';
    const CLICKED_TRY_AGAIN = 'clickedTryAgain';
    const CLICKED_CONTINUE = 'continue';

    return {
      CLICKED_CANCEL,
      CLICKED_TRY_AGAIN,
      CLICKED_CONTINUE,
    };
  }

  emitEventClick(clickType: string) {
    this.$emit('clickEventModal', clickType);
  }
}
