












































































































import {
  Component, Vue, Watch, Mixins,
} from 'vue-property-decorator';
import { eachDayOfInterval } from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import SubjectInfo from '@/components/Subject/SubjectInfo/index.vue';
import SubjectBox from '@/components/Subject/SubjectBox/index.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import ListAccordionLoading from '@/components/ListAccordionLoading/index.vue';
import ModalWarning from '@/components/Modal/ModalWarning/index.vue';

import CourseAccordion from './components/CourseAccordion/CourseAccordion.vue';
import Sidebar from './components/CourseSidebar/CourseSidebar.vue';
import ModalChangedClass from './components/CourseModal/ModalChangedClass.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import SetSidebar from '@/mixins/SetSidebar';
import ProgressBarCourse from '@/mixins/ProgressBarCourse';

import { CourseClass, CourseClassSelect, LastAccessedContentCourse } from '@/globalInterfaces/Course';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import { ID_COURSE_VEST_MAPA } from '../../constant/CoursesId';

const MODAL_WARNING = 'ModalWarning';
const CLICKED_CANCEL = 'clickedCancel';
const CLICKED_TRY_AGAIN = 'clickedTryAgain';
const CLICKED_CONTINUE = 'continue';
const TOPIC_COURSE_TIME_TABLE = 'BAIXAR CRONOGRAMA';

@Component({
  components: {
    PageHeader,
    ListAccordionLoading,
    SubjectBox,
    CourseAccordion,
    SubjectInfo,
    Footer,
    ModalChangedClass,
    ProgressBar,
    ContainerFluid,
    ModalWarning,
  },
})
export default class Courses extends Mixins(SetSidebar, ProgressBarCourse) {
  private isMobile = false;
  private isLoading = true;
  private showAllNextWeek = false;
  private isSuccessfulChangedClass = false;

  private idClassSelected = 0;
  private keyChangedClass = 0;

  private idClassWeekCourse = '';
  private componentNameModal = '';

  protected lastWeekCourseAccessed: LastAccessedContentCourse | Record<string, any> = {};
  private timeTableCourse: Record<string, any> = {};
  private course: Record<string, any> = {};
  private listClassCourse: Array<CourseClassSelect> = [];

  private setModal = new ActiveModal();

  async created() {
    this.onMobileChange();
    this.setSideBook();
    this.setBreadCrumbs();
    await this.getLastWeekCourseAccessed();
    await this.getCourse();
    await this.getListClassCourse();
    this.checkClickModal();
  }

  mounted() {
    this.setModalWarning();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setListClassCourseSelect', []);
  }

  get routeParams() {
    return this.$route.params;
  }

  get listNextWeek(): CourseClassSelect | Record<string, any> {
    if (this.listClassCourse && this.listClassCourse.length) {
      const { idClass } = this.routeParams;

      const currentClass = this.listClassCourse.find((classItem: CourseClassSelect) => classItem.id === Number(idClass));

      if (currentClass) return currentClass;
    }

    return {};
  }

  get hasMoreNextWeeks() {
    return this.listOldAndCurrentWeek && this.listOldAndCurrentWeek.length && this.listNextWeek && Object.entries(this.listNextWeek).length;
  }

  get dataModalChagendClass() {
    return this.$store.getters.dataModalChagendClass;
  }

  @Watch('$route.params', {
    deep: true,
  })
  async getLastWeekCourseAccessed() {
    try {
      const { idCourses, idClass } = this.routeParams;
      const lastWeekCourseAccessed = await this.HistoricService.getCourseAccessWeek(Number(idCourses));

      if (
        lastWeekCourseAccessed
        && lastWeekCourseAccessed?.idCourses === Number(idCourses)
        && lastWeekCourseAccessed?.idClasses === Number(idClass)
      ) {
        this.lastWeekCourseAccessed = lastWeekCourseAccessed;
        this.idClassWeekCourse = lastWeekCourseAccessed.idClasses.toString();
      } else {
        this.idClassWeekCourse = idClass;
      }
    } catch (error) {
      if (error?.response?.status !== STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) {
        this.$store.dispatch('Toast/setToast', {
          text: 'Erro ao carregar última semana acessada.',
          status: 'error',
        });
      }

      const { idClass } = this.routeParams;
      this.idClassWeekCourse = idClass;
      console.error(error);
    }
  }

  @Watch('course', { deep: true })
  setProgressBar() {
    if (this.course) {
      const idCourse = this.course?.idCourses;
      this.getWatchedWeek(idCourse);
    }
  }

  @Watch('$route.params.idCourses')
  async getCourse() {
    try {
      const { idCourses } = this.routeParams;
      const response = await this.CoursesService.getCourses();

      if (response && response.length) {
        const currentCourse = response.find((course: Record<string, any>) => String(course.idCourses) === idCourses);

        if (currentCourse) {
          this.course = currentCourse;
        }
      }
    } catch (error) {
      console.error({ error });
    }

  }

  @Watch('$route.params', {
    deep: true,
  })
  async getListClassCourse() {
    try {
      const { idCourses } = this.routeParams;

      const classes = await this.CoursesService.getCourseClasses(Number(idCourses));

      if (classes && classes.length) {
        this.listClassCourse = classes.map((classItem: CourseClass) => {
          if (this.idClassWeekCourse && String(classItem.id) === this.idClassWeekCourse) {
            return {
              ...classItem,
              selected: true,
            };
          }
          return {
            ...classItem,
            selected: false,
          };
        });
      }
      this.checkUserClassCourse(classes, Number(idCourses));
      this.$store.commit('setListClassCourseSelect', this.listClassCourse);
    } catch (error) {
      console.error({ error });
    }
  }

  @Watch('idClassWeekCourse')
  @Watch('$route.params', {
    deep: true,
  })
  async getClassWeekCourse() {
    try {
      this.isLoading = true;
      const { idCourses } = this.routeParams;

      const response = await this.CoursesService.getClassWeekCourse(idCourses, this.idClassWeekCourse);

      if (response && response.length) {
        this.listOldAndCurrentWeek = this.filterOldAndCurrentWeek(response);
        this.listOldAndCurrentWeek = this.createNewListWithoutTimeTable(this.listOldAndCurrentWeek);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as semanas do curso.',
        status: 'error',
      });

    } finally {
      this.isLoading = false;
    }
  }

  checkUserClassCourse(listClass: Array<Record<string, any>>, idCourse: number) {
    const existClass = !!listClass.find((classItem: Record<string, any>) => classItem.id === Number(this.idClassWeekCourse));

    if (!existClass) this.openModal(idCourse);
  }

  openModal(idCourse: number) {
    this.$Bubble.emit('modalCourse', { idCourse });
  }

  createNewListWithoutTimeTable(listWeekCourse: Array<Record<string, any>>) {
    return listWeekCourse.map((week: Record<string, any>) => ({ ...week, topics: this.findTimeTableAndSeparateFromTheList(week) }));
  }

  findTimeTableAndSeparateFromTheList(weekCourse: Record<string, any>) {
    if (!weekCourse || !weekCourse.topics) return [];

    return weekCourse.topics.reduce((acc: Array<Record<string, any>>, topic: Record<string, any>) => {
      if (topic.title === TOPIC_COURSE_TIME_TABLE) this.timeTableCourse = this.timeTableCourse.length ? this.timeTableCourse : topic;
      else acc.push(topic);
      return acc;
    }, []);
  }

  filterOldAndCurrentWeek(listWeek: Array<Record<string, any>>) {
    const currentDate = new Date();

    const currentClass = listWeek.filter((classWeek: Record<string, any>) => (new Date(classWeek?.end_date) < currentDate) || (new Date(classWeek.init_date) <= currentDate && new Date(classWeek.end_date) >= currentDate));

    return currentClass;
  }

  setListInfoWeek(week: Record<string, any>) {
    if (week && Object.entries(week).length) {
      let quantityAulas = 0;
      let quantityArchives = 0;

      quantityAulas = this.setQuantityTopics(week, 'videos', quantityAulas);
      quantityArchives = this.setQuantityTopics(week, 'archives', quantityArchives);

      return [
        {
          quantity: quantityAulas,
          title: 'Aulas',
        },
        {
          quantity: quantityArchives,
          title: 'Materiais',
        },
      ];
    }

    return [];
  }

  setQuantityTopics(data: Record<string, any>, type: string, initialValue: number) {
    let quantityFiles: number = initialValue;

    if (data.topics && data.topics.length) {
      const total = quantityFiles;
      data.topics.forEach((topic: Record<string, any>) => {
        quantityFiles += this.setQuantityTopics(topic, type, total);
      });
    } else if (data[type]) {
      quantityFiles = data[type]?.length;
      return quantityFiles;
    }

    return quantityFiles;
  }

  getNextWeek(totalWeekNext: number, totalListOldAndCurrentWeek: []) {
    let totalShowWeek = totalWeekNext - totalListOldAndCurrentWeek.length;
    totalShowWeek = totalShowWeek >= 0 ? totalShowWeek : 0;

    if (this.showAllNextWeek) {
      return totalShowWeek;
    }

    if (totalShowWeek >= 3) {
      return 3;
    }

    return totalShowWeek;
  }

  setNextWeek() {
    this.showAllNextWeek = !this.showAllNextWeek;
  }

  setShowIndex(week: Record<string, any>) {
    const { idCourses, idClass } = this.routeParams;

    if (
      this.lastWeekCourseAccessed?.idCourses === Number(idCourses)
      && this.lastWeekCourseAccessed?.idClasses === Number(idClass)) {
      return week.id === this.lastWeekCourseAccessed.idWeek;
    }

    return false;
  }

  setListWeekDay(week: Record<string, any>) {
    const dateInitial = new Date(week.init_date);
    const dateFinal = new Date(week.end_date);

    const listDate = eachDayOfInterval({
      start: dateInitial.setDate(dateInitial.getDate() + 1),
      end: dateFinal.setDate(dateFinal.getDate() + 1),
    });

    return listDate;
  }

  nameWeek(totalWeeks: number, totalCurrentWeek: number, indexWeek: number) {
    const number = totalCurrentWeek + (indexWeek + 1);

    if (number <= totalWeeks) {
      return `SEMANA ${this.modifierNumber(number)}`;
    }

    return '';
  }

  modifierNumber(value: number) {
    return value < 10 ? `0${value}` : String(value);
  }

  @Watch('$responsive.isMobile')
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
    this.$store.commit('setSidebar', !this.isMobile);
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'cursos', to: null },
    ]);
  }

  @Watch('listClassCourse')
  @Watch('timeTableCourse')
  setSideBook() {
    const ComponentClass = Vue.extend(Sidebar);
    const instance = new ComponentClass(
      {
        propsData:
        {
          cover: this.course?.thumb,
          colorBook: this.course?.color,
          title: this.course?.title,
          dateInitial: this.listNextWeek?.init_date,
          dateFinished: this.course?.dateEnd,
          quantityWeek: this.listNextWeek?.totalWeeks,
          listClassCourse: this.listClassCourse,
          timeTableCourse: this.timeTableCourse,
          idClass: this.routeParams.idClass,
          keyChangedClass: this.keyChangedClass,
          idClassSelected: this.idClassSelected,
        },
      },
    );

    this.keyChangedClass = 0;

    instance.$on('classSelected', (idClassSelected: number) => {
      this.idClassWeekCourse = idClassSelected.toString();
      this.idClassSelected = idClassSelected;
      this.setModal.activeModal('modalChangedClass');
      this.componentNameModal = MODAL_WARNING;
      this.setClassSelected('');
    });

    this.setSidebar(instance);
  }

  @Watch('dataModalChagendClass', { deep: true })
  setData() {
    if (this.dataModalChagendClass.componentNameModal) {
      this.isSuccessfulChangedClass = this.dataModalChagendClass.isSuccessfulChangedClass;
      this.componentNameModal = this.dataModalChagendClass.componentNameModal;
    }
  }

  setClassSelected(statusClicked: string) {
    if (statusClicked === CLICKED_CANCEL) {
      const { idClass } = this.routeParams;
      this.updateListClassCourseAndCommit(Number(idClass));
      return;
    }

    if (statusClicked === CLICKED_TRY_AGAIN || statusClicked === CLICKED_CONTINUE) {
      this.keyChangedClass += 1;
      this.setSideBook();
      return;
    }
    this.updateListClassCourseAndCommit(this.idClassSelected);
  }

  updateListClassCourseAndCommit(idClass: number) {
    const newList = this.listClassCourse.map((classItem: CourseClassSelect) => {
      if (classItem.id === idClass) {
        return {
          ...classItem,
          selected: true,
        };
      }
      return {
        ...classItem,
        selected: false,
      };
    });
    this.$store.commit('setListClassCourseSelect', newList);
  }

  checkClickModal() {
    const modal = document.getElementById('modalChangedClass');
    if (modal) {
      modal.addEventListener('click', (e) => {
        if (e.target === modal) {
          const { idClass } = this.routeParams;
          this.updateListClassCourseAndCommit(Number(idClass));
        }
      });
    }
  }

  setModalWarning() {
    const { idCourses } = this.routeParams;

    if (!(Number(idCourses) === ID_COURSE_VEST_MAPA)) return;

    this.setModal.activeModal('modalWarning');
  }
}
