



























import { Component, Vue, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

const ID_ERROR = 'error';
const ID_SUCCESS = 'success';
const NAME_ATTRIBUTE_MODAL = 'modal';

@Component({
  components: {
    Loading,
  },
})
export default class ModalSuccessError extends Vue {
  @Prop() isSuccessful!: boolean;

  private data = {
    success: {
      id: 'success',
      src: 'woohooo.svg',
      alt: 'Woohooo',
      description: 'Alteração realiza com sucesso!',
      textButton: 'Entendi',
    },
    error: {
      id: 'error',
      src: 'Ooops.svg',
      alt: 'Ooops',
      description: 'Algo deu errado! Não foi possível realizar a mudança de turma. Tente novamente.',
      textButton: 'Tentar novamente',
    },
  }

  get infoModal() {
    if (this.isSuccessful) return this.data.success;
    return this.data.error;
  }

  get isLoading() {
    return this.$store.getters.isLoadingChangedClass;
  }

  checkButtomIdToCloseModal(id: string) {
    return id === ID_SUCCESS ? NAME_ATTRIBUTE_MODAL : '';
  }

  verifyActionButtom(id: string) {
    if (id === ID_ERROR) this.$emit('clickedTryAgain');
  }
}
