
























import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

const TEXT_BUTTON_DEFAULT = 'Entendi';

@Component({
  components: {
    Modal,
  },
})
export default class ModalWarning extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) description!: string;
  @Prop({ default: TEXT_BUTTON_DEFAULT }) textButton!: string;
}
