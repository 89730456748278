import { render, staticRenderFns } from "./SubjectQuestions.vue?vue&type=template&id=42f97349&scoped=true&"
import script from "./SubjectQuestions.vue?vue&type=script&lang=ts&"
export * from "./SubjectQuestions.vue?vue&type=script&lang=ts&"
import style0 from "./SubjectQuestions.scss?vue&type=style&index=0&id=42f97349&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f97349",
  null
  
)

export default component.exports