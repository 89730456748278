






import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class SubjectQuestions extends Vue {
  @Prop() id!: number;
  @Prop() questions!: Array<Record<string, any>>;

  private formatted!: string;

  showInfoQuestions(id: number, questions: Array<Record<string, any>>) {
    const info = questions.find((element: Record<string, any>) => element.idExercice === id);
    if (info) {
      if (info.questionCount > 1) {
        this.formatted = `${info.questionCount} questões | ${this.setTime(info.questionCount)}`;
      } else {
        this.formatted = `${info.questionCount} questão | ${this.setTime(info.questionCount)}`;
      }
    }
    return this.formatted;
  }

  setTime(numberQuestion: number) {
    const formatted = moment.utc((numberQuestion * 180) * 1000).format('HH:mm:ss');
    return formatted;
  }
}
